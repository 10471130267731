@import url(/style/css/common.css);

.feedback[data-v-5f33e3d4] {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.feedback .input-area[data-v-5f33e3d4]{
  flex:1;
  border: 1px solid #eee;
}
.feedback .opt[data-v-5f33e3d4]{
        justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    padding: 20px 0;
}


/*# sourceMappingURL=chunk-61c810a5.c2d25c7f.css.map*/